<template>
	<div class="billing-code-settings">
		<div class="consultant-selection">
			<template v-if="consultantList.length > 1">
				<label>Manage codes for </label>
				<v-select
					v-model="consultant"
					class="consultant-select"
					label="name"
					:options="consultantList"
					:clearable="false"
					:searchable="true"
				>
					<template #option="c">
						<svg-icon
							:icon="c.isGroup ? 'account-multiple' : c.id ? 'account' : 'domain'"
							fixed
							inline
							style="opacity: 0.7;"
						/>
						{{ c.name }}
					</template>
				</v-select>
			</template>
			<svg-icon v-if="isLoading" icon="spinner" pulse class="is-muted" />
		</div>
		<form @submit.prevent="addCode">
			<div class="billing-code-name">
				<ast-input ref="nameInput" v-model.trim="billingCode.name" label="Name" />
			</div>
			<div class="billing-code-value">
				<ast-input v-model.trim="billingCode.value" label="Value" />
			</div>
			<button
				class="btn btn-primary save-button"
				:disabled="isSaving || !billingCode.name || !billingCode.value"
				type="submit"
			>
				Add Billing Code
			</button>
		</form>
		<div v-if="!codes.length" class="list-empty">
			No billing codes have been added.
		</div>
		<data-table
			v-show="codes.length"
			:is-hoverable="false"
			:is-loading="isLoading"
			style="background: var(--secondary-bg); border: 1px solid var(--secondary-border); overflow-y: auto;"
		>
			<thead>
				<tr>
					<th></th>
					<th>Name</th>
					<th>Value</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(code, i) in codes" :key="i">
					<td style="width: 32px;">
						<button class="delete-button" title="Delete" @click="deleteCode(code)">
							<svg-icon icon="close" />
						</button>
					</td>
					<td>
						{{ code.name }}
					</td>
					<td>
						{{ code.value }}
					</td>
				</tr>
			</tbody>
		</data-table>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import DataTable from '@components/DataTable'
import AstInput from '@components/Input'
import { billingCodes } from '@services/billingCodes'
import consultations from '@services/consultationService'
import { userData } from '@services/userData'
import { eventBus } from '@services/eventBus'

export default {
	name: 'BillingCodesSettingView',
	components: {
		AstInput,
		DataTable,
	},
	props: {
		selectConsultantId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			billingCode: {
				name: '',
				value: '',
			},
			codes: [],
			consultant: null,
			consultantList: [],
			isAdmin: false,
			isLoading: true,
			isSaving: false,
		}
	},
	watch: {
		async consultant() {
			this.loadConsultantBillingCodes()
		},
	},
	async mounted() {
		this.isLoading = true
		try {
			this.isAdmin = userData.permissions.serverAdministration
			if (this.isAdmin) {
				this.consultantList = [...this.$store.state.static.consultantsForAdmin]
				this.consultantList.unshift({
					id: null,
					name: this.$store.getters.activeClinic.name,
				})
			} else {
				const responses = await Promise.all([
					consultations.getConsultantGroups(userData.claims.userId),
					consultations.getConsultantInfo(userData.claims.userId),
				])
				this.consultantList = [...responses[0], responses[1]]
			}
			if (this.selectConsultantId) {
				this.consultant = this.consultantList.find(c => c.id === this.selectConsultantId)
			}
			if (!this.consultant) this.consultant = this.consultantList[0]
		} finally {
			this.isLoading = false
		}
	},
	methods: {
		async loadConsultantBillingCodes() {
			try {
				this.isLoading = true
				const codes = await billingCodes.get(this.consultant.id)
				if (codes) this.codes = codes.filter(c => !c.deleted)
			} finally {
				this.isLoading = false
			}
		},
		async deleteCode(code) {
			if (!(await showConfirm('Are you sure you want to delete this billing code?'))) return
			code.deleted = true
			await this.save()
			const codeIndex = this.codes.indexOf(code)
			if (codeIndex >= 0) this.codes.splice(codeIndex, 1)
		},
		async addCode() {
			if (!this.billingCode.name || !this.billingCode.value) return
			this.codes = [...this.codes, { ...this.billingCode }]
			await this.save()
			await this.loadConsultantBillingCodes()
			this.resetForm()
			this.$refs.nameInput.$refs.input.focus()
		},
		async save() {
			try {
				this.isSaving = true
				await billingCodes.save(this.codes, this.consultant.id)
				eventBus.post('refresh-report') // update billing code selector if a report is open
			} finally {
				this.isSaving = false
			}
		},
		resetForm() {
			this.billingCode.name = ''
			this.billingCode.value = ''
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';
.billing-code-settings {
	.consultant-selection {
		margin: -2px -2px 16px -2px;
		& > * {
			margin: 2px;
		}
		.consultant-select {
			display: inline-block;
			min-width: 200px;
		}
	}
	form {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		flex-shrink: 0;
		margin: -4px -4px 16px -4px;
	}
	th {
		font-size: 0.9em;
	}
	td {
		width: 1%; // shrink first two columns
		white-space: nowrap;
		&:last-child {
			width: auto;
			white-space: normal;
		}
	}
	.billing-code-name,
	.billing-code-value,
	.save-button {
		margin: 4px;
	}
	.billing-code-name {
		flex-basis: 25%;
		min-width: 120px;
	}
	.billing-code-value {
		flex-basis: 50%;
		white-space: pre;
	}
	.list-empty {
		text-align: center;
		margin: 25px 0;
	}
	.save-button {
		height: 36px;
	}
	.delete-button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		background: var(--button-default-bg);
		color: var(--button-default-color);
		outline: none;
		border: 0;
		width: 24px;
		height: 24px;
		cursor: pointer;
		&:hover {
			background: var(--button-danger-bg);
			color: var(--button-danger-color);
		}
	}
	@media (max-width: $mqMedium) {
		.billing-code-name,
		.billing-code-value,
		.save-button {
			flex-basis: 100%;
		}
	}
}
</style>
